import React, { useEffect, useState, useContext } from 'react'
import { Dialog, Image, Actions } from '../components/library'
import { navigate } from 'gatsby';
import patchUser from "../utils/patchUser";
import { retrieveSymptoms, clearSymptoms, checkForReset, } from "../utils/symptoms-bluetooth";
import { ImpactContext } from '../components/Layout';
import { Button } from '../components/library';
// import { preparePuck } from '../utils/symptoms-bluetooth'

function startCheckin(setFailed: (d: any) => void, setReset: (d: boolean) => void, setPreparedDone: () => void ){

  function navigateToForm(rawSymptoms: string[]){
    const symptoms = rawSymptoms.map((s) => ({time: new Date(s).toISOString()}));
    clearSymptoms();
    navigate('/asthma-check-in-form', {state: {symptoms: symptoms}});
  }
  retrieveSymptoms(navigateToForm, setFailed, setReset, setPreparedDone);
}
function ImportSymptomsPage() {

  const { user, userContext, wearableInitialAttrs } = useContext(ImpactContext)
  const [failed, setFailed] = useState(null)
  const [attrs, setAttrs] = useState(wearableInitialAttrs)
  const [puckReset, setPuckReset] = useState(false);
  const [preparedDone, setPreparedDone] = useState(false);

  useEffect(() => {
    startCheckin(setFailed, setPuckReset, () => setPreparedDone(true));
  }, []);

  useEffect(() => {
    if(failed === false){
      patchUser(user?.id, userContext, {field_wearable_on: true})
    }
    if(failed === true){
      setAttrs({
        handleClose: (e) => navigate('/'),
        title: "Connection failed"
      })
    }else{
      setAttrs(wearableInitialAttrs)
    }
  }, [failed])

  useEffect(() => {
    if(puckReset){
      const appendTitle = preparedDone ? "" : " Please wait.";
      setAttrs({
        handleClose: (e) => navigate('/asthma-check-in-form'),
        title: "Your wearable has not been prepared or was reset." + appendTitle,
        description: preparedDone ?
          "For now, please enter your symptoms manually, or click the Initialize button on the Account page and try again." :
          "Please wait while your wearable is being prepared."
      });
    }
  }, [puckReset, preparedDone]);
  return (
      <div>
          <Dialog
              id="sample-dialog"
              open={true}
              {...attrs}
          >
             <Image src={'/watch.png'} width="40%" aspectRatio={0.5}  />
             {failed && (
               <>
              <Actions 
                  dismissOnClick={e => navigate('/asthma-check-in-form')}
                  dismissLabel="Add manually"
                  submitOnClick={e => {
                    setFailed(null)
                    startCheckin(setFailed, setPuckReset);
                  }}
                  submitLabel="Retry"
              />
              </>
             )}
            {!failed && puckReset && preparedDone && (
              <>
              <Actions
                submitOnClick={e => navigate('/asthma-check-in-form')}
                submitLabel="Add manually"
                dismissOnClick={e => navigate('/')}
                dismissLabel="Return to Home"
              />
              <Button
              color="tertiary"
              variant="contained"
              onClick={e => {
                setFailed(null)
                startCheckin(setFailed, setPuckReset);
                // setAttrs(wearableInitialAttrs)
                }}
              >
              Initialize
            </Button>
              </>
            )}

          </Dialog>
      </div>
  );
}

export default ImportSymptomsPage;
